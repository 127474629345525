import { Textarea } from '@mui/joy'
import shadows from '@mui/material/styles/shadows'
import React, { useEffect } from 'react'
import { Redirect } from '../../IconsPath'

const Task = ({ task }) => {

    useEffect(() => {
        const handleCopy = (e) => {
            e.preventDefault();
        };

        document.addEventListener('copy', handleCopy);

        return () => {
            document.removeEventListener('copy', handleCopy);
        };
    }, []);

    return (
        <div className='bg-[#F5F5FF] p-2 px-3 w-full rounded-md' style={{ userSelect: 'none' }}>
            <div className='text-black font-semibold py-2 text-lg'> Task </div>

            <div className=''>
                <div className='text-black font-semibold pb-1 pt-2 text-sm'>Objective</div>
                <div className='text-black font-semibold text-sm opacity-[0.7]'>
                    <Textarea
                        style={{ color: 'black', backgroundColor: '#F5F5FF', border: 'none', boxShadow: 'none', fontSize: '14px', userSelect: 'none' }}
                        disabled
                        value={task?.question}
                    />
                </div>
            </div>
            {
                ((task?.task_type === 'DOC' || task?.task_type === 'DOC_UPLOAD') && task?.doc_urls) ?
                    <div className='w-full'>
                        <div className='text-black font-semibold pt-3 pb-1 text-sm'>Reading Material</div>
                        {
                            task?.doc_urls?.map((url, i) => (
                                <a
                                    target="_blank"
                                    key={i}
                                    href={url}
                                    className='bg-[#ededff] text-[#3838F1] text-sm w-full p-2 rounded-md font-semibold flex items-center justify-between mb-1'
                                    style={{ userSelect: 'none' }}
                                >
                                    <span>Reading Material {i + 1}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d={Redirect} fill="#171719" />
                                    </svg>
                                </a>
                            ))
                        }
                    </div>
                    :
                    (task?.task_type === 'CODE' && task?.constraints) ?
                        <div className=''>
                            <div className='text-black font-semibold pt-3 pb-1 text-sm'>Constraint</div>
                            <div className='text-black font-semibold text-sm opacity-[0.7]'>
                                <Textarea
                                    style={{ color: 'black', backgroundColor: '#F5F5FF', border: 'none', boxShadow: 'none', fontSize: '14px', userSelect: 'none' }}
                                    disabled
                                    value={task?.constraints}
                                />

                            </div>
                        </div>
                        :
                        ""
            }

        </div>
    )
}

export default Task