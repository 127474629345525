import React, { useContext, useEffect, useState } from 'react'
import '../../Common/Common.css'
import { GeneralContext } from '../../Context';
import moment from 'moment';
import { ReactComponent as Calendar } from '../../Assets/Calendar.svg'
import { ReactComponent as Task_Complete_Icon } from '../../Assets/Task_Complete_Icon.svg'
import CompletedBanner from '../../Assets/Completed_Banner.png'
import axios from 'axios';
import { API } from '../../Config';
import { useNavigate } from 'react-router-dom';

const CourseCompletionModal = ({ setShowCouseCompleteView }) => {

    const { selectedCourse, courseDetails, setStudentInfo } = useContext(GeneralContext);

    const [tasks, setTasks] = useState(0)
    const [videos, setVideos] = useState(0)
    const [batchEvent, setBatchEvent] = useState(0)
    const navigate = useNavigate()

    const calculateWeeksDifference = (start, end) => {
        const oneWeek = 1000 * 60 * 60 * 24 * 7;
        const startDate = new Date(start);
        const endDate = new Date(end);
        const diffInMilliseconds = endDate - startDate;
        return Math.round(diffInMilliseconds / oneWeek);
    };

    const calculateTasksAndVideos = () => {
        if (courseDetails) {
            let tempTasks = 0
            let tempVideos = 0
            courseDetails?.children?.map((module => {
                module?.children?.map((topic) => {
                    if (topic?.tasks && topic?.tasks?.length > 0) {
                        tempTasks = tempTasks + topic?.tasks?.length
                    }
                    if (topic?.videos && topic?.videos?.length > 0) {
                        tempVideos = tempVideos + topic?.videos?.length
                    }
                })
            }))
            setTasks(tempTasks)
            setVideos(tempVideos)
        }
    }

    const getBatchEvents = async () => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            const { data } = await axios.get(`${API}/batch-event/${courseDetails?.batch_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setBatchEvent(data?.data)

        } catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log(e.message)
        }
    }

    const formatDate = (dateString) => {
        return moment(dateString).format('D MMMM');
    };

    useEffect(() => {
        getBatchEvents()
        calculateTasksAndVideos()
    }, [])

    return (
        <div className='custom-font flex flex-col'>
            <div className='grid gap-3'>
                <h2 className='text-[#050519] font-semibold text-2xl '>Course Complete</h2>
                <p className='text-[#6E6E6F] text-[15px] font-semibold'>You have successfully finished your course {courseDetails?.name}. This has been a long journey and is worth celebrating! You should be proud. 🥳</p>
            </div>

            <div className='flex pt-3 w-full justify-between'>
                <div className='rounded-lg w-[350px] border-[#D7D7D7] border-[1px] h-[230px] bg-[#F4F4F4] p-3 flex flex-col relative'>
                    <img className='absolute right-6 w-[200px] top-7' src={CompletedBanner} alt="" />

                    <div className='text-[25px] text-[#050519] font-semibold'>{courseDetails?.name}</div>
                    <div className='text-[#6E6E6F] flex gap-2 items-center text-sm mt-auto'>
                        <Calendar style={{ color: '#6E6E6F' }} />
                        {formatDate(courseDetails?.start_date)} - {formatDate(courseDetails?.end_date)} ({calculateWeeksDifference(courseDetails?.start_date, courseDetails?.end_date)} weeks)
                    </div>
                    <div className='text-[#42B25B] flex gap-2 items-center text-sm mt-1 font-semibold'>
                        <Task_Complete_Icon />
                        <div >100% Completed</div>
                        <span >• {courseDetails?.points_earned} pts</span>
                    </div>
                </div>

                <div className='rounded-lg w-[330px] border-[#D7D7D7] border-[1px] h-[200px] bg-[#FCFCFF] p-3 flex flex-wrap'>
                    <div className='w-[50%] mb-3 mt-1'>
                        <div className='text-[#6E6E6F] text-[15px] font-semibold'>
                            Total Points Earned
                        </div>
                        <div className='text-[#050519] text-[25px] font-semibold'>
                            {courseDetails?.points_earned}
                        </div>
                    </div>
                    <div className='w-[50%] mt-1'>
                        <div className='text-[#6E6E6F] text-[15px] font-semibold'>
                            Number Of Tasks
                        </div>
                        <div className='text-[#050519] text-[25px] font-semibold'>
                            {tasks}
                        </div>
                    </div>
                    <div className='w-[49%]'>
                        <div className='text-[#6E6E6F] text-[15px] font-semibold'>
                            Total Videos Watched
                        </div>
                        <div className='text-[#050519] text-[25px] font-semibold'>
                            {videos}
                        </div>
                    </div>
                    <div className='w-[50%]'>
                        <div className='text-[#6E6E6F] text-[15px] font-semibold'>
                            Total Events Scheduled
                        </div>
                        <div className='text-[#050519] text-[25px] font-semibold'>
                            {batchEvent?.length}
                        </div>
                    </div>
                </div>
            </div>
            <div className='border-b-[1px] border-[#A7A7A7] my-5'>

            </div>
            <button className='text-white bg-[#3838F1] rounded-[4px] py-2 px-3 ml-auto mt-auto'
                onClick={() => {
                    setShowCouseCompleteView(false)
                }}
            >
                Dismiss
            </button>
        </div>
    )
}

export default CourseCompletionModal