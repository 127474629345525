import React, { useContext, useState } from 'react';
import '../../Common/Common.css';
import { Input } from '@mui/joy';
import axios from 'axios';
import { API } from '../../Config';
import { CircularProgress } from '@mui/material';
import { GeneralContext } from '../../Context';

const AccountSetting = ({ setShowAccountSettingModal }) => {

    let student = localStorage.getItem("student_data")
    student = JSON.parse(student)

    const [name, setName] = useState(student?.name)
    const [email, setEmail] = useState(student?.email)
    const [loading, setLoading] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const { setStudentInfo } = useContext(GeneralContext);


    const updateStudent = async () => {
        try {
            setLoading(true)
            const { data } = await axios.patch(`${API}/student?student_id=${student?.student_id}`, {
                name: name,
            })
            setLoading(false)
            setStudentInfo(data?.data[0])
            localStorage.setItem("student_data", JSON.stringify(data?.data[0]))
            setShowAccountSettingModal(false)
            console.log(data?.data[0])
        }
        catch (e) {
            setLoading(false)
            console.log("Error", e.message)
        }
    }

    return (
        <div className='custom-font flex flex-col'>
            <div className='grid gap-3 mb-3'>
                <h2 className='text-[#050519] font-semibold text-2xl'>Setting</h2>
            </div>
            <div className='flex flex-col gap-[8px] h-[170px] overflow-scroll rounded-[5px] mt-2'>
                <div className='font-bold'>Your Name</div>
                <div className='common-input-field' style={{ width: '100%', fontWeight: 600 }}>
                    <Input
                        type='name'
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                            // setShowErrorMessage(false)
                        }}
                        // onKeyDown={(e) => handleKeyDown(e, sendOtpToEmail)}
                        placeholder='Name'
                    // disabled={loading}
                    />
                    {/* {showErrorMessage && <div className='error-message'>{errorMessage}</div>} */}
                </div>
                <div className='font-bold mt-2'>Your Email</div>
                <div className='common-input-field' style={{ width: '100%', fontWeight: 600 }}>
                    <Input
                        type='email'
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            // setShowErrorMessage(false)
                        }}
                        // onKeyDown={(e) => handleKeyDown(e, sendOtpToEmail)}
                        placeholder='Email'
                        disabled={true}
                    />
                    {/* {showErrorMessage && <div className='error-message'>{errorMessage}</div>} */}
                </div>
            </div>
            <div className='border-b-[1px] border-[#A7A7A7] mt-3 mb-5 opacity-[0.3] mr-1'></div>
            <div className='flex ml-auto gap-1'>
                <button
                    className='text-[#3838F1] rounded-[4px] py-[5px] px-3 mt-auto mr-1 border-[#EDEDFF] border-[2px]'
                    onClick={() => setShowAccountSettingModal(false)}
                >
                    Cancel
                </button>
                {
                    loading ?
                        <button
                            className='text-white bg-[#3838F1] rounded-[4px] py-[4px] px-3 ml-auto mt-auto mr-1 w-[80px] border-[#3838F1] border-[2px]'
                            // onClick={() => updateStudent(false)}
                        >
                            <CircularProgress size={18} color="inherit" />
                        </button>
                        :
                        <button
                            className='text-white bg-[#3838F1] rounded-[4px] py-[5px] px-3 ml-auto mt-auto mr-1 w-[80px] border-[#3838F1] border-[2px]'
                            onClick={() => updateStudent()}
                        >
                            Submit
                        </button>
                }
            </div>
        </div>
    );
};

export default AccountSetting;