import React, { useContext, useEffect, useRef, useState } from "react";
import "./Progress.css";
import { ReactComponent as RewardIcon } from "../../Assets/Reward_icon.svg";
import { CircularProgress, Collapse } from '@mui/material'
import { ReactComponent as Down_Icon } from '../../Assets/Down_Icon.svg'
import { ReactComponent as Task_Complete_Icon } from '../../Assets/Task_Complete_Icon.svg'
import { ReactComponent as Ellipse_19 } from '../../Assets/Ellipse_19.svg'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import { GeneralContext } from "../../Context";
import axios from "axios";
import { API } from "../../Config";
import { Coding_Task_Icon, MCQ_Task_Icon, PointsIcon, Reading_Task_Icon, Videos_Task_Icon } from '../../IconsPath'
import { Calendar } from '../../IconsPath'
import moment from 'moment';
import { useNavigate } from 'react-router-dom'

const Progress = ({ selectedVideo, setAllowCall, handleShowVideoComponent, handleShowCodeEditor }) => {
    const [isOpenSecond, setIsOpenSecond] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [drawers, setDrawers] = useState([]);
    const [videoPercentage, setVideoPercentage] = useState(0);
    const [taskPercentage, setTaskPercentage] = useState(0);
    const [taskPoints, setTaskPoints] = useState(0)
    const { courseDetails, selectedTopic, setSelectedTopic, videoDetails, selectedModule, taskDetails, setStudentInfo } = useContext(GeneralContext);
    const modalRef = useRef(null);
    const secondModalRef = useRef(null);
    const navigate = useNavigate()
    const [mcqTasks, setMcqTasks] = useState(0)
    const [mcqTaskProgress, setMcqTaskProgress] = useState(0)
    const [mcqTaskPoints, setMcqTaskPoints] = useState(0)
    let p = 0

    const formatDate = (dateString) => {
        return moment(dateString).format('D MMMM');
    };

    function extractHours(timeString) {
        if (!timeString) {
            return null
        }
        const [hours] = timeString.split(':');
        return parseInt(hours, 10);
    }

    const handleShowChapterSummary = (chapterId) => {
        const newOpenDrawers = [];
        const currentDrawer = drawers[chapterId]
        newOpenDrawers[chapterId] = !currentDrawer;
        setDrawers(newOpenDrawers);
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setIsOpenSecond(false)
    };

    const calculateTaskProgress = () => {
        let points = 0
        let taskCount = 0
        taskDetails?.forEach((task) => {
            if (task?.status === "COMPLETED") {
                taskCount++
            }
            points = points + task?.points
        })
        setTaskPoints(points)
        setTaskPercentage((taskCount / (taskDetails?.length)) * 100)
    }

    const toggleDropdown2 = () => {
        setIsOpenSecond(!isOpenSecond);
        setIsOpen(false)
    };

    const calculateVideoProgress = async () => {
        try {
            let videoCount = 0
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            const { data } = await axios.get(`${API}/student-progress/videos?progress_id=${selectedTopic?.progress_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            data?.data?.forEach((video) => {
                if (video?.status === "COMPLETED") {
                    videoCount++
                }
            })
            setVideoPercentage((videoCount / (videoDetails?.length)) * 100)
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log(e.message)
        }
    }

    const handleClickOutsideSecond = (event) => {
        if (secondModalRef.current && !secondModalRef.current.contains(event.target) && !document.querySelector('.second-modal').contains(event.target)) {
            setIsOpenSecond(false)
        }
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && !document.querySelector('.first-modal').contains(event.target)) {
            setIsOpen(false)
        }
    };

    useEffect(() => {
        if (isOpenSecond) {
            document.addEventListener('mousedown', handleClickOutsideSecond);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutsideSecond);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSecond);
        };
    }, [isOpenSecond]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        calculateVideoProgress()
    }, [selectedVideo, courseDetails])

    useEffect(() => {
        calculateTaskProgress()
    }, [taskDetails, courseDetails])

    useEffect(() => {
        const fetchData = async () => {
            const data = courseDetails
            // if (data?.status === "PENDING") {
            //     setDrawers([true]);
            // }
            // else if (data?.status === "IN_PROGRESS") {
            setDrawers([])
            let temp = []
            data?.children?.some((module, i) => {
                if (module?.progress_id !== selectedModule?.progress_id) {
                    temp.push(null)
                    return false
                }
                else {
                    temp.push(true)
                    return true
                }
            })
            setDrawers(temp)
            // }
        };

        fetchData();
    }, [isOpen]);

    const calculateMcqTaskProgress = () => {
        let total = 0
        let completed = 0
        let points = 0
        taskDetails?.forEach((task) => {
            if (task?.task_type === 'MCQ') {
                total = total + 1
                points = points + task?.points
                if (task?.status === 'COMPLETED') {
                    completed = completed + 1
                }
            }
        })
        setMcqTasks(total)
        setMcqTaskProgress((completed / total) * 100)
        setMcqTaskPoints(points)
    }

    const increament = () => {
        p++
    }

    function formatTopicDate(dateString) {
        if (!dateString) {
            return null
        }
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short' };
        let formattedDate = date.toLocaleDateString('en-GB', options)
        formattedDate = formattedDate.replace(/([a-zA-Z]{3})/, (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase());

        return formattedDate;
    }

    useEffect(() => {
        calculateMcqTaskProgress()
    })

    return (
        <div className={`progress grid gap-2`} style={{
            height: `${isOpen ? "90%" : ""}`
        }}>
            <div className="chat-header bg-white w-full flex gap-3 px-2 pb-2">
                <div className=" border-none w-full flex gap-2">
                    <div
                        className={`dropdown-header first-modal h-[24px] ${isOpen ? " bg-[#3838F1]" : " bg-[#F5F5FF]"}`}
                        onClick={toggleDropdown}
                    >
                        <span className={` text-sm ${isOpen ? "text-[#F5F5FF]" : "text-gray-500"}`}>
                            {courseDetails?.name?.length > 25
                                ? `${courseDetails?.name.substring(0, 25)}...`
                                : courseDetails?.name}
                        </span>
                    </div>
                    <div
                        className={`dropdown-header2 second-modal h-[24px] ${isOpenSecond ? " bg-[#3838F1]" : " bg-[#F5F5FF]"}`}
                        onClick={toggleDropdown2}
                    >
                        <span className={`text-sm ${isOpenSecond ? "text-[#F5F5FF]" : "text-[#060206]"}`} >
                            <span className={`text-xs ${isOpenSecond ? "text-[#AEAED4]" : "text-gray-500"} mr-1`} >
                                {formatTopicDate(selectedTopic?.topic_start_date)}
                            </span>{" "}
                            {selectedTopic?.node_name?.length > 35
                                ? `${selectedTopic.node_name.substring(0, 35)}...`
                                : selectedTopic?.node_name}
                        </span>
                    </div>
                    <div className='bg-[#F5F5FF] text-[#3838F1] flex items-center font-semibold text-xs rounded-md px-2 gap-1 h-[25px] ml-auto'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 10 12" fill="none">
                            <path d={PointsIcon} fill="#3838F1" />
                        </svg>
                        {courseDetails?.points_earned} pts
                    </div>
                </div>
            </div>
            {isOpen && (
                <div
                    className='course-container progress-container'
                    ref={modalRef}
                >
                    <div className="">
                        <div className="course-heading">
                            {courseDetails?.name}
                        </div>
                        <div className='course-description'>

                            <div className='flex gap-2 items-center'>
                                {
                                    Number(courseDetails?.progress) === 100 ?
                                        <Task_Complete_Icon />
                                        :
                                        Number(courseDetails?.progress) === 0 ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                                <circle opacity="0.5" cx="4" cy="4" r="4" fill="#AEAED4" />
                                            </svg>
                                            :
                                            <div className='circular-progress-container w-[16px]'>
                                                <CircularProgressbar
                                                    value={Number(courseDetails?.progress)}
                                                    strokeWidth={50}
                                                    styles={buildStyles({
                                                        strokeLinecap: "butt"
                                                    })}
                                                />
                                            </div>
                                }
                                <div className='text-[#42B25B] mr-1'>
                                    {Math.round(Number(courseDetails?.progress))}% Complete
                                    <span className=''> • {courseDetails?.points_earned} pts</span>
                                </div>
                                <div className='text-[#6E6E6F] flex gap-1 items-center text-xm ml-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                                        <path d={Calendar} fill="#6E6E6F" />
                                    </svg>
                                    {formatDate(courseDetails?.start_date)} - {formatDate(courseDetails?.end_date)}
                                </div>
                                <span className='flex items-center ml-2 text-xm gap-1'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 10 12" fill="none">
                                        <path d={PointsIcon} fill="#6E6E6F" />
                                    </svg>
                                    {courseDetails?.points} points
                                </span>
                            </div>

                        </div>
                    </div>
                    <div className="course-details">
                        <div className="line"></div>
                        <div className='course-content'>
                            {
                                courseDetails?.children?.map((module, i) => (
                                    <div className={`${drawers[i] ? "bg-[#F4F4F4]" : ""} rounded-lg`}>
                                        <div className={`module cursor-pointer`} onClick={(event) => {
                                            event.stopPropagation();
                                            handleShowChapterSummary(i)
                                        }}>
                                            <div>
                                                <div className={`rounded-lg flex items-center gap-2`}>
                                                    {
                                                        Number(module?.progress) === 100 ?
                                                            <Task_Complete_Icon />
                                                            :
                                                            Number(module?.progress) === 0 ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 8 8" fill="none">
                                                                    <circle opacity="0.5" cx="4" cy="4" r="4" fill="#AEAED4" />
                                                                </svg>
                                                                :
                                                                <div className='circular-progress-container w-[16px]'>
                                                                    <CircularProgressbar
                                                                        value={Number(module?.progress)}
                                                                        strokeWidth={50}
                                                                        styles={buildStyles({
                                                                            strokeLinecap: "butt"
                                                                        })}
                                                                    />
                                                                </div>
                                                    }

                                                    <span className={`${(drawers[i] || Number(module?.progress) === 100) ? "text-black" : "text-[#6E6E6F]"}`}>{module?.node_name}</span>
                                                </div>
                                                {/* <div className='flex gap-2 items-center'>
                                                        <div className='text-[#A7A7A7] text-sm'>12-15 Jun</div>
                                                        <div className='text-[#A7A7A7] text-sm'>95 points</div>
                                                        <div className='text-[#A7A7A7] text-sm'>6 hrs, 1 Test</div>
                                                    </div> */}
                                                <div className={`${drawers[i] ? "" : "hidden"} text-[#6E6E6F] text-xs font-semibold ml-6 mt-2`}>{module?.points} points</div>
                                            </div>
                                            <div className='expand-icon-container'>
                                                <Down_Icon />
                                            </div>
                                        </div>
                                        {
                                            drawers[i] &&
                                            <div className="line"></div>
                                        }
                                        <Collapse in={drawers[i]}>
                                            <div className='chapter-summary text-left' style={{ fontFamily: 'Figtree, sans-serif' }}>
                                                {
                                                    module?.children?.map((topic, j) => (
                                                        <div className={`topic group`}>
                                                            <div className='flex items-center gap-2 w-[75%]'>
                                                                {
                                                                    Number(topic?.progress_percentage) === 100 ?
                                                                        <Task_Complete_Icon />
                                                                        :
                                                                        Number(topic?.progress_percentage) === 0 ?
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 8 8" fill="none">
                                                                                <circle opacity="0.5" cx="4" cy="4" r="4" fill="#AEAED4" />
                                                                            </svg>
                                                                            :
                                                                            <div className='circular-progress-container w-[16px]'>
                                                                                <CircularProgressbar
                                                                                    value={Number(topic?.progress_percentage)}
                                                                                    strokeWidth={50}
                                                                                    styles={buildStyles({
                                                                                        strokeLinecap: "butt"
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                }
                                                                {/* <span className={`${topic?.progress_id === selectedTopic?.progress_id ? "text-black" : "text-[#6E6E6F]"}`}>{topic?.node_name}</span> */}
                                                                <span className={`${topic?.is_accessable ? "text-black" : "text-[#6E6E6F]"} font-semibold`}>{formatTopicDate(topic?.topic_start_date)}</span>
                                                                <span className={`${topic?.is_accessable ? "text-black" : "text-[#6E6E6F]"} font-semibold w-[83%]`}>{topic?.node_name}</span>

                                                            </div>
                                                            <div className='flex items-center ml-auto'>
                                                                <div className="button-container ml-auto opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                    {
                                                                        topic?.progress_id !== selectedTopic?.progress_id &&
                                                                        <button
                                                                            className='text-[12px] text-white rounded-md px-2 w-fit flex items-center justify-center bg-[#3838F1] font-medium'
                                                                            onClick={() => {
                                                                                if (topic?.progress_id !== selectedTopic?.progress_id) {
                                                                                    handleShowCodeEditor(false)
                                                                                    handleShowVideoComponent(false)
                                                                                    setSelectedTopic(topic)
                                                                                    setIsOpen(false)
                                                                                    setAllowCall(true)
                                                                                }
                                                                            }}
                                                                        >
                                                                            {(Number(topic?.progress_percentage) === 100) ?
                                                                                "See Content"
                                                                                :
                                                                                (Number(topic?.progress_percentage) === 0 && topic?.is_accessable) ?
                                                                                    "Start"
                                                                                    :
                                                                                    topic?.is_accessable && "Continue"
                                                                            }
                                                                        </button>
                                                                    }
                                                                </div>
                                                                <div className='time text-xs ml-2 flex gap-2'>
                                                                    {
                                                                        (topic?.duration && extractHours(topic?.duration)) ?
                                                                            <span>{extractHours(topic?.duration) + "hrs"}</span>
                                                                            :
                                                                            ""
                                                                    }
                                                                    {
                                                                        topic?.points ?
                                                                            <span>{topic?.points} Pts</span>
                                                                            :
                                                                            ""
                                                                    }                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Collapse>
                                    </div>

                                ))
                            }
                        </div>
                    </div>
                </div>
            )}

            <div className="flex justify-center">
                {isOpenSecond && (
                    <div className="flex flex-col bg-white z-50 absolute  rounded-md shadow-md  w-[420px]" ref={secondModalRef}>
                        <div className="py-2 px-3 grid gap-1 border-b-[1px] border-[#D7D7D7]">
                            <div>
                                <h3 className="font-semibold font-sans text-lg">
                                    {selectedTopic?.node_name}
                                </h3>
                            </div>
                            <div className="flex items-center gap-1">
                                <span className=" font-semibold text-[13px] text-[#42B25B]">
                                    {Math.round((Number(selectedTopic?.points_earned) / Number(selectedTopic?.points)) * 100)}% Complete
                                </span>
                                {/* <CalenderIcon />
                                <span className="text-[#6E6E6F] text-[14px] font-semibold">
                                    12 Jun
                                </span>
                                <TimerIcon />
                                <span className="text-[#6E6E6F] text-[14px] font-semibold">
                                    2 hrs
                                </span> */}
                                <div className="flex items-center">
                                    <RewardIcon />
                                    <span className="text-[#6E6E6F] text-[13px] font-semibold">
                                        {selectedTopic?.points} points
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className=" p-3 scrollable-div flex-grow">
                            {courseDetails && (
                                <div className={`rounded-lg bg-[#F4F4F4] cursor-pointer`}>
                                    <div className="dropdown-topic">
                                        <div className="flex gap-2 items-center dropdown-course-inner-div pt-2 pl-2 pb-2 pr-3 justify-between">
                                            <div className="flex items-center gap-2">
                                                {
                                                    videoPercentage === 100 ?
                                                        <Task_Complete_Icon />
                                                        :
                                                        videoPercentage === 0 ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 14 15" fill="none">
                                                                <path d={Videos_Task_Icon} fill="#6E6E6F" />
                                                            </svg>
                                                            :
                                                            <div className='circular-progress-container w-[16px]'>
                                                                <CircularProgressbar
                                                                    value={videoPercentage}
                                                                    strokeWidth={50}
                                                                    styles={buildStyles({
                                                                        strokeLinecap: "butt"
                                                                    })}
                                                                />
                                                            </div>
                                                }
                                                <h3 className={`text-black text-sm font-semibold`}>
                                                    {/* {subItem?.node_name} */}
                                                    {selectedTopic?.videos?.length} Topic Videos
                                                </h3>
                                            </div>
                                            <div className="flex gap-2">
                                                <span className="text-[#6E6E6F] text-[12px] font-semibold">
                                                    {/* 2 hrs */}
                                                </span>
                                                <span className="text-[#6E6E6F] text-[12px] font-semibold">
                                                    {selectedTopic?.videos?.length * 5} pts
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            mcqTasks > 0 ?
                                                <div className='flex items-center p-2 pr-3'>
                                                    {
                                                        mcqTaskProgress === 100 ?
                                                            <Task_Complete_Icon />
                                                            :
                                                            mcqTaskProgress === 0 ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 14 15" fill="none">
                                                                    <path d={MCQ_Task_Icon} fill="#6E6E6F" />
                                                                </svg>
                                                                :
                                                                <div className='circular-progress-container w-[16px]'>
                                                                    <CircularProgressbar
                                                                        value={mcqTaskProgress}
                                                                        strokeWidth={50}
                                                                        styles={buildStyles({
                                                                            strokeLinecap: "butt"
                                                                        })}
                                                                    />
                                                                </div>
                                                    }
                                                    <div className='text-black font-semibold text-sm ml-2'>
                                                        {mcqTasks} Multiple Choice Questions
                                                    </div>
                                                    <div className='ml-auto flex gap-2'>
                                                        {/* <span className='text-[#6E6E6F] text-xs'>1.5 hrs</span> */}
                                                        <span className='text-[#6E6E6F] text-xs font-semibold'>{mcqTaskPoints} pts</span>
                                                    </div>
                                                </div>
                                                :
                                                ""
                                        }
                                        {
                                            // selectedTopic?.tasks?.length > 0 &&
                                            taskDetails?.map((task, i) => (
                                                task?.task_type !== "MCQ" ?
                                                    <div className="flex gap-2 items-center dropdown-course-inner-div pt-2 pl-2 pb-2 pr-3 justify-between">
                                                        {increament()}
                                                        <div className="flex items-center gap-2">
                                                            {
                                                                task?.status === 'COMPLETED' ?
                                                                    <Task_Complete_Icon />
                                                                    :
                                                                    task?.task_type === "DOC" ?
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 12 12" fill="none">
                                                                            <path d={Reading_Task_Icon} fill="#6E6E6F" />
                                                                        </svg>
                                                                        :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 13 13" fill="none">
                                                                            <path d={Coding_Task_Icon} fill="#6E6E6F" />
                                                                        </svg>
                                                            }
                                                            <h3 className={`text-black text-sm font-semibold`}>
                                                                {
                                                                    (task?.task_title !== null && task?.task_title !== '') ?
                                                                        task?.task_title
                                                                        :
                                                                        task?.task_type === 'DOC' ? `Reading Task ${p}` : `Coding Task ${p}`
                                                                }

                                                            </h3>
                                                        </div>
                                                        <div className="flex gap-2">
                                                            <span className="text-[#6E6E6F] text-[12px] font-semibold">
                                                                {/* 2 hrs */}
                                                            </span>
                                                            <span className="text-[#6E6E6F] text-[12px] font-semibold">
                                                                {task?.points} pts
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                            ))
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Progress;
