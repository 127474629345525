import React from 'react'

const VideoSummary = ({ summary }) => {

    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        return formattedTime;
    }

    return (
        <div className='bg-[#F5F5FF] p-2 px-3 w-full rounded-md '>
            <div className='text-black font-semibold py-2 text-lg'> Summary </div>
            {
                summary?.summary?.topics?.map((t, i) => (
                    <div className='text-black font-semibold mb-3'>
                        <div className='flex items-center gap-2 '>
                            <span className='text-black font-bold text-lg'>• </span>
                            <div className='text-[#3838F1]'>
                                {formatTime(t?.start_time)}
                            </div>
                            <div className=' text-[15px]'>
                                {t?.topic}
                            </div>
                        </div>
                        <div className='opacity-[0.7] text-base'>
                            <div>
                                {summary?.summary?.points_to_remember[i]}
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default VideoSummary